<template>
    <YsPagination ref="node"
        :total="0"
        :showFooter="false">
        <template #header>
            <el-button type="success" @click="addInfo">新增消息</el-button>
        </template>
        <el-table :data="infoList" height="100%" style="width: 100%;" border>
            <el-table-column label="编码" prop="id" width="100" />
            <el-table-column label="标题" prop="title" width="180" show-overflow-tooltip />
            <el-table-column label="内容" prop="content" show-overflow-tooltip />
            <el-table-column label="操作" width="160">
                <template #default="{ row }">
                    <el-button size="small" @click="editInfo(row)">编辑</el-button>
                    <el-button size="small" type="danger" @click="deleteEvt(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
    </YsPagination>

    <el-dialog v-model="show" :title="!infoObj.id ? '新增' : '编辑'" center>
        <el-form :model="infoObj" :rules="infoRules" ref="formNode" status-icon>
            <el-form-item label="标题" prop="title">
                <el-input v-model="infoObj.title"
                    placeholder="输入标题"
                    maxlength="30"
                    show-word-limit
                    type="text"/>
            </el-form-item>
            <el-form-item label="内容" prop="content">
                <el-input v-model="infoObj.content"
                    placeholder="输入标题"
                    maxlength="500"
                    :rows="5"
                    show-word-limit
                    type="textarea"/>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="show = false">关闭</el-button>
                <el-button type="primary" @click="saveEvt">
                    保存
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>

<script>
import { infoListApi } from '@/api/info.js'
import { infoAddApi, infoDeleteApi, infoUpdateApi } from '../../api/info'
import { ElMessage, ElMessageBox } from 'element-plus'

export default {
    data() {
        return {
            show: false,
            infoList: [],
            infoObj: {
                id: '',
                title: '',
                content: ''
            },
            infoRules: {
                title: [
                    {required: true, message: '请输入标题'}
                ],
                content: [
                    {required: true, message: '请输入内容'}
                ]
            }
        }
    },
    methods: {
        async getList() {
            let result = await infoListApi()
            if (result.meta.code === 0) {
                this.infoList = result.data || []
            }
        },
        addInfo() {
            this.show = true
            this.infoObj = {
                id: '',
                title: '',
                content: ''
            }
        },
        editInfo(row) {
            this.show = true
            this.infoObj = {...row}
        },
        deleteEvt(row) {
            ElMessageBox.confirm(
                '确定要删除此条消息?',
                'error',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error',
                }
            )
            .then(async () => {
                let res = await infoDeleteApi(row.id)
                if (res.meta?.code === 0) {
                    ElMessage.success('删除成功')
                    this.getList()
                } else {
                    ElMessage.error('删除失败，请重试')
                }
                
            })
            .catch(() => {})
        },
        saveEvt() {
            this.$refs.formNode.validate(async flag => {
                if (flag) {
                    if (this.infoObj.id) {
                        let newRes = await infoUpdateApi(this.infoObj)
                        if (newRes.meta?.code === 0) {
                            this.show = false
                            this.getList()
                            ElMessage.success('编辑成功')
                        } else {
                            ElMessage.error('编辑失败，请重试')
                        }
                    } else {
                        let newRes = await infoAddApi({
                            title: this.infoObj.title,
                            content: this.infoObj.content
                        })
                        if (newRes.meta?.code === 0) {
                            this.show = false
                            this.getList()
                            ElMessage.success('新增成功')
                        } else {
                            ElMessage.error('新增失败，请重试')
                        }
                    }
                }
            })
        }
    },
}
</script>

<style lang='less' scoped>
.content-box {
    margin: 20px;
    background-color: #ffffff;
}

.addimage-box {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed #4c4d4f;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.addimage-box:hover {
    border: 1px dashed #0f54dc;
}

.img-box {
    position: relative;

    >.tips {
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ffffff;
        position: absolute;
        font-size: 24px;
        top: 0;
        left: 0;
        cursor: pointer;
        margin-right: 10px;

        .my-tips {
            display: none;
        }
    }

    .tips:hover {
        background-color: rgba(1, 1, 1, 0.5);

        >.my-tips {
            display: block;
        }
    }
}
</style>
