import Ajax from "@/utils/request.js"
import { INFO_ADD_URL, INFO_DELETE_URL, INFO_LIST_URL, INFO_UPDATE_URL } from "../config/user.cfg"

export function infoListApi() {
    return Ajax({
        url: INFO_LIST_URL,
        params: {type: 'all'}
    })
}

/**
 * 添加信息接口
 * @param {{title: string,content: string}} data 
 * @returns 
 */
export function infoAddApi(data) {
    return Ajax({
        url: INFO_ADD_URL,
        method: 'POST',
        data
    })
}

/**
 * 修改信息接口
 * @param {{title: string,content: string,id: number}} data 
 * @returns 
 */
export function infoUpdateApi(data) {
    return Ajax({
        url: INFO_UPDATE_URL,
        method: 'PUT',
        data
    })
}

/**
 * 删除接口
 * @param {string} id 
 * @returns 
 */
export function infoDeleteApi(id) {
    return Ajax({
        url: INFO_DELETE_URL + id,
        method: 'DELETE'
    })
}